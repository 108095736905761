import Vue from 'vue'

export default {
    // Supliers
    set_suppliers(state, suppliers){
        state.suppliers = suppliers
    },
    supplier_updated(state, supplier){
        const index = state.suppliers.findIndex(element => element.id === supplier.id)
        Vue.set(state.suppliers, index, supplier)
    },
    supplier_created(state, supplier) {
        state.suppliers.push(supplier)
    },
    set_suppliers_count(state, count) {
        state.suppliersCount = count
    },
    set_supplier_to_edit(state, supplier) {
        state.supplierToEdit = supplier
    },
    empty_supplier_to_edit(state) {
        state.supplierToEdit = null
    },

    // Products
    set_products(state, products){
        state.products = products
    },
    product_updated(state, product){
        const index = state.products.findIndex(element => element.id === product.id)
        Vue.set(state.products, index, product)
    },
    product_created(state, product) {
        state.products.push(product)
    },
    set_products_count(state, count) {
        state.productsCount = count
    },
    set_product_to_edit(state, product) {
        state.productToEdit = product
    },
    empty_product_to_edit(state) {
        state.productToEdit = null
    },

    setBucket(state, bucket) {
        state.bucket = bucket
    },
    addedToBucket(state, item) {
        state.bucket.push(item)
    },
    editedBucketItem(state, item) {
        const index = state.bucket.findIndex(element => element.id === item.id)
        Vue.set(state.bucket, index, item)
    },
    setBucketCount(state, count) {
        state.bucketCount = count
    },
    removeFromBucket(state, bucketItem) {
        state.bucket.splice(
            state.bucket.findIndex(item => bucketItem.id === item.id),
            1
        )
    },
    orderConfirmed(state, order) {
        state.orders.push(order)
        state.bucket = []
    },
    set_orders(state, orders){
        state.orders = orders
    },
    set_orders_count(state, count) {
        state.ordersCount = count
    },
    setOrder(state, order) {
        state.order = order
    },
    emptyOrder(state) {
        state.order = null
    },
    setEmailSettings(state, settings) {
        if (settings.length > 0) {
            state.emailSettings = {...settings[0]}
        }
    },
    emailSettingsUpdated(state, setting) {
        state.emailSettings = {...setting}
    },
    emailSettingsAdded(state, setting) {
        state.emailSettings = {...setting}
    },
    // BVModal
    SET_BV_MODAL: (s, val) => s.bvModal = val
}
