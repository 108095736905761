export default {
  getBites: state => state.bitesList,
  getLotWithMaterialItems: state => state.lotWithMaterialItems,
  getLotWithMaterialItemsCount: state => state.lotWithMaterialItemsCount,
  getDiagnosisGroups: state => state.diagnosisGroupsList,
  getDiagnosis: state => state.diagnosis,
  getDiagnosisCount: state => state.diagnosisCount,
  getPlans: state => state.plansList,
  getPlansCount: state => state.plansCount,
  getServicesGroupsMain: state => state.servicesGroupsMain,
  getServicesGroups: state => state.servicesGroups,
  getServicesGroupsAll: state => state.servicesGroupsAll,
  getDiagnosisGroupsMain: state => state.diagnosisGroupsMain,
  getDiagnosisSubGroups: state => state.diagnosisSubGroups,
  getDiagnosisGroupsAll: state => state.diagnosisGroupsAll,
  getMaterialGroups: state => state.materialGroups,
  getMaterialLotsPerPage: state => state.materialLotsPerPage,
  getMaterialLotsPerPageTotalCount: state => state.materialLotsPerPageCount,
  roleList: state => state.roles,
  getDiscount: state => id => {
    return state.discountCardsList.find(d => d.id === id)
  },
  getDentalServices: state => state.services,
  getDentalServicesCount: state => state.servicesCount,
  getServiceById: state => id => state.services.find(s => s.id === id),
  abilities: state => state.abilities,
  integrators: state => state.integrators,
  serviceTimes: state => state.serviceTimes,
  patientTypes: state => state.patientTypes,
  findIntegrator: state => id => {
    return state.integrators.find(i => i.id === id) || null
  },
  findServiceTime: state => id => {
    return state.serviceTimes.find(i => i.id === id) || null
  },
  integratorsCount: state => state.integratorsCount,
  integratorTypeOptions: state => state.integratorTypeOptions,
  serviceTimesCount: state => state.serviceTimeCount,
  patientTypesCount: state => state.patientTypesCount,
  integrator: state => state.integrator,
  integratorSettings: state => state.integratorSettings,
  serviceTime: state => state.serviceTime,
  patientType: state => state.patientType,
  employee: state => state.employee,
  permissions: state => state.permissions,
  getEmployeeByClinicPerPage: state => state.employeeByClinicPerPage,
  getEmployeeByClinicPerPageCount: state => state.employeeByClinicPerPageCount,
  getContactsFrom: state => state.contactsFromList,
  getContactFrom: state => state.contactFrom,
  getPaymentMethodExtendedCategories: state => state.paymentMethodExtendedCategories,
  getPaymentDirections: state => state.paymentDirections,
  getPaymentDirectionTypes: state => state.paymentDirectionTypes,
  getServiceTypes: state => state.serviceTypes,
  getDiscountCards: state => state.discountCardsList,
  paymentMethods: state => state.paymentMethods,
  advisoryOpinions: state => state.advisoryOpinions,
  advisoryOpinionsCount: state => state.advisoryOpinionsCount,
  getInsuranceCompanies: state => state.insuranceCompanies,
  getInsuranceCompaniesCount: state => state.insuranceCompaniesCount,
  getSearchTemplates: state => state.searchTemplates,
  getSearchTemplatesCount: state => state.searchTemplatesCount,
  getNotificationConditionCategoriesPerPage: state => state.notificationConditionCategoriesPerPage,
  getNotificationConditionCategoriesTotalCount: state => state.notificationConditionCategoriesTotalCount,
  getNotificationConditionCategories: state => state.notificationConditionCategories,
  getLegalPersons: state => state.legalPersons,
  getLegalPersonsCount: state => state.legalPersonsCount,
  thirdPartyOrganizations: state => state.thirdPartyOrganizations,
  thirdPartyOrganizationsCount: state => state.thirdPartyOrganizationsCount,
  getPatientAppealResultsPerPage: state => state.patientAppealResultsPerPage,
  getPatientAppealResultsCount: state => state.patientAppealResultsCount,
  getPatientAppealResults: state => state.patientAppealResults,
  getContacts: state => state.contacts,
  getPhysicianProtocolFields: state => state.physicianProtocolFields,
  getPhysicianProtocolFieldValues: state => state.physicianProtocolFieldValues,
  getPhysicianProtocolSections: state => state.physicianProtocolSections,
  getPhysicianProtocolSubsections: state => state.physicianProtocolSubsections,
  getPhysicianProtocolPerPage: state => state.physicianProtocolPerPage,
  getPhysicianProtocolPerPageCount: state => state.physicianProtocolPerPageCount,
  getAnalysesSynevoPerPage: state => state.analysesSynevoPerPage,
  getAnalysesSynevoPerPageCount: state => state.analysesSynevoPerPageCount,
  getPatientCardTemplateGroups: state => state.patientCardTemplateGroups,
  getTreatmentFeaturesPerPage: state => state.treatmentFeaturesPerPage,
  getTreatmentFeaturesPerPageCount: state => state.treatmentFeaturesPerPageCount,
  getTreatmentFeaturesForDropdown: state => state.treatmentFeaturesForDropdown,
  getPlansWithTimePerPage: state => state.plansWithTimePerPage,
  getPlansWithTimePerPageCount: state => state.plansWithTimePerPageCount,
  getDentalServicesWithTimePerPage: state => state.dentalServicesWithTimePerPage,
  getDentalServicesWithTimePerPageCount: state => state.dentalServicesWithTimePerPageCount,
  getUserServiceForTimePropertyPerPage: state => state.userServiceForTimePropertyPerPage,
  getUserServiceForTimePropertyPerPageCount: state => state.userServiceForTimePropertyPerPageCount,
  getUserDentalServicePropertyPerPage: state => state.userDentalServicePropertyPerPage,
  getUserDentalServicePropertyPerPageCount: state => state.userDentalServicePropertyPerPageCount,
  getUserTreatmentPlanPropertyPerPage: state => state.userTreatmentPlanPropertyPerPage,
  getUserTreatmentPlanPropertyPerPageCount: state => state.userTreatmentPlanPropertyPerPageCount,
  getUserActivityLogItemsPerPage: state => state.userActivityLogItemsPerPage,
  getUserActivityLogItemsPerPageCount: state => state.userActivityLogItemsPerPageCount,
  getPricingTypeGroups: state => state.pricingTypeGroups,
  getPricingTypeGroupItems: state => state.pricingTypeGroupItems,
  getMaterialUnitTypes: state => state.units,
  getNds: state => state.nds,
  getMaterialLotTypes: state => state.materialLotTypes,
  getPatientAppointmentStatuses: state => state.patientAppointmentStatuses,
  getPatientAppointmentStatusesPerPage: state => state.patientAppointmentStatusesPerPage,
  getPatientAppointmentStatusesPerPageCount: state => state.patientAppointmentStatusesPerPageCount,
  getTimezones: state => state.timeZones,
  getPatientContactViewPasswordSendingMethods: state => state.patientContactViewPasswordSendingMethods,
  getAppointmentNotificationOnCustomDayOptions: state => state.appointmentNotificationOnCustomDayOptions,
  getAppointmentNotificationByLastAppointmentOptions: state => state.appointmentNotificationByLastAppointmentOptions,
  getAppointmentNotificationByGenderOptions: state => state.appointmentNotificationByGenderOptions,
  getFaqQuestions: state => state.faqQuestions,
  medicalServiceTypePerPage: state => state.medicalServiceTypePerPage,
  medicalServiceTypePerPageCount: state => state.medicalServiceTypePerPageCount,
  specialistTypes: state => state.specialistTypes,
  specialistTypesPerPage: state => state.specialistTypesPerPage,
  specialistTypesPerPageCount: state => state.specialistTypesPerPageCount,
  dentalServiceSpecialistTypePerPage: state => state.dentalServiceSpecialistTypePerPage,
  dentalServiceSpecialistTypePerPageCount: state => state.dentalServiceSpecialistTypePerPageCount,
  materialPriceList: state => state.materialPriceList,
  materialPriceListItems: state => state.materialPriceListItems,
  materialPriceListItemsCount: state => state.materialPriceListItemsCount,
  onlineAppointmentServiceTimeIntervals: state => state.onlineAppointmentServiceTimeIntervals,
  integratorBranchesPerPage: state => state.integratorBranchesPerPage,
  integratorBranchesPerPageCount: state => state.integratorBranchesPerPageCount,
  materialPriceCalcSystem: s => s.materialPriceCalcSystem,
  recommendedVisitTypes: s => s.recommendedVisitTypes,
  recommendedVisitTypesCount: s => s.recommendedVisitTypesCount,
  specialistTypeWageDeductionsAmountPerPage: s => s.specialistTypeWageDeductionsAmountPerPage,
  specialistTypeWageDeductionsAmountPerPageCount: s => s.specialistTypeWageDeductionsAmountPerPageCount,
  counterparties: s => s.counterparties,
  counterpartiesCount: s => s.counterpartiesCount
}
