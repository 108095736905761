export default {
  // Materials WriteOff
  SET_MATERIALS_WRITE_OFF(state, materials_write_off) {
    state.materials_write_off = materials_write_off
  },
  // Cash register
  ADD_PATIENT_PAYMENT(state, patient_payment) {
    state.patientsPayments.push(patient_payment)
    state.patientsPaymentsCount += 1
  },
  EDIT_PATIENT_PAYMENT(state, patient_payment) {
    if (state.patientsPayments && state.patientsPayments.length) {
      const idx = state.patientsPayments.findIndex(payment => payment.id === patient_payment.id)
      Object.keys(patient_payment).forEach(key => {
        state.patientsPayments[idx][key] = patient_payment[key]
      })
    }
  },
  SET_PATIENTS_PAYMENTS: (state, items) => state.patientsPayments = items,
  SET_PATIENTS_PAYMENTS_COUNT: (state, count) => state.patientsPaymentsCount = count,
  DELETE_PATIENT_PAYMENT(state, patient_payment_id) {
    const idx = state.patientsPayments.findIndex(patient_payment => patient_payment.id === patient_payment_id)
    state.patientsPayments.splice(idx, 1)
    state.patientsPaymentsCount -= 1
  },
  SET_CASH_REGISTER_TOTAL(state, cash_register_total) {
    state.cash_register_total = cash_register_total
  },
  SET_TOTAL_BY_LINE_OF_WORK(state, total_by_line_of_work) {
    state.total_by_line_of_work = total_by_line_of_work
  },
  SET_TOTAL_BY_SNAPSHOT(state, total_by_snapshot) {
    state.total_by_snapshot = total_by_snapshot
  },
  SET_TOTAL_BY_SELLING(state, total_by_selling) {
    state.total_by_selling = total_by_selling
  },
  SET_TOTAL_BY_PREPAYMENT(state, total_by_selling) {
    state.total_by_prepayment = total_by_selling
  },
  SET_TOTAL_BY_DISCOUNT(state, total_by_discount) {
    state.total_by_discount = total_by_discount
  },
  // Debtors
  SET_DEBTORS(state, debtors) {
    state.debtors = debtors
  },
  // Insurances
  SET_INSURANCES(state, insurances) {
    state.insurances = insurances
  },
  INSURANCE_ADDED(state, insurance) {
    state.insurances.push(insurance)
  },
  INSURANCE_EDITED(state, insurance) {
    const idx = state.insurances.findIndex(item => item.id === insurance.id)
    Object.keys(insurance).forEach(key => {
      state.insurances[idx][key] = insurance[key]
    })
  },
  DELETE_INSURANCE(state, insuranceId) {
    const idx = state.insurances.findIndex(insurance => insurance.id === insuranceId)
    state.insurances.splice(idx, 1)
  },
  // Certificates
  SET_CERTIFICATES(state, items) {
    state.certificates = items
  },
  CERTIFICATE_ADDED(state, item) {
    state.certificates.push(item)
  },
  CERTIFICATE_EDITED(state, certificate) {
    const idx = state.certificates.findIndex(item => item.id === certificate.id)
    Object.keys(certificate).forEach(key => state.certificates[idx][key] = certificate[key])
  },
  DELETE_CERTIFICATE(state, id) {
    const idx = state.certificates.findIndex(c => c.id === id)
    state.certificates.splice(idx, 1)
  },
  // Cashless payments
  SET_CASHLESS_PAYMENTS(state, items) {
    state.cashlessPayments = items
  },
  CASHLESS_PAYMENT_ADD(state, item) {
    state.cashlessPayments.push(item)
  },
  CASHLESS_PAYMENT_EDIT(state, item) {
    const idx = state.cashlessPayments.findIndex(payment => payment.id === item.id)
    Object.keys(item).forEach(key => {
      state.cashlessPayments[idx][key] = item[key]
    })
  },
  DELETE_CASHLESS_PAYMENT(state, id) {
    const idx = state.cashlessPayments.findIndex(item => item.id === id)
    state.cashlessPayments.splice(idx, 1)
  },
  // Billing statement
  SET_BILLING_STATEMENTS(state, items) {
    state.billingStatements = items
  },
  SET_BILLING_STATEMENTS_COUNT(state, count) {
    state.billingStatementsCount = count
  },
  BILLING_STATEMENT_ADD(state, item) {
    state.billingStatements.push(item)
    state.billingStatementsCount += 1
  },
  BILLING_STATEMENT_EDIT(state, item) {
    const idx = state.billingStatements.findIndex(i => i.id === item.id)
    Object.keys(item).forEach(key => state.billingStatements[idx][key] = item[key])
  },
  DELETE_BILLING_STATEMENT(state, id) {
    const idx = state.billingStatements.findIndex(item => item.id === id)
    state.billingStatements.splice(idx, 1)
    state.billingStatementsCount -= 1
  },
}
