import chatConstants from './chatConstants'
import routerConstants from './routerConstants'
import patientAppointmentConstants from './patientAppointmentConstants'
import roleConstants from './roleConstants'
import patientCalendarConstants from './patientCalendarConstants'
import leadConstants from './leadConstants'
import dentalLaboratoryPurchaseOrderConstants from './dentalLaboratoryPurchaseOrderConstants'
import userConstants from '@/constants/userConstants'
import dentalServiceConstants from '@/constants/dentalServiceConstants'
import treatmentPlanConstants from '@/constants/treatmentPlanConstants'
import materialConstants from '@/constants/materialConstants'
import integratorConstants from '@/constants/integratorConstants'
import actConstants from '@/constants/actConstants'
import employeeConstants from '@/constants/employeeConstants'
import revenueConstants from '@/constants/revenueConstants'
import treatmentDiaryConstants from '@/constants/treatmentDiaryConstants'
import dentalServiceGroupConstants from '@/constants/dentalServiceGroupConstants'

export default {
  chat: chatConstants,
  router: routerConstants,
  patientAppointment: patientAppointmentConstants,
  role: roleConstants,
  patientCalendar: patientCalendarConstants,
  lead: leadConstants,
  dentalLaboratoryPurchaseOrder: dentalLaboratoryPurchaseOrderConstants,
  user: userConstants,
  dentalService: dentalServiceConstants,
  dentalServiceGroup: dentalServiceGroupConstants,
  treatmentPlan: treatmentPlanConstants,
  material: materialConstants,
  integrator: integratorConstants,
  act: actConstants,
  employee: employeeConstants,
  revenue: revenueConstants,
  treatmentDiary: treatmentDiaryConstants
}
