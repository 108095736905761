import axios from '@/libs/axios'

export default {
  // Materials Write Off
  getMaterialsWriteOffExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `materials-write-off-export/`, method: 'GET', params: params, responseType: 'blob',
      })
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getMaterialsWriteOff({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `materials-write-off/`, method: 'GET', params: params
      })
        .then(res => {
          commit('SET_MATERIALS_WRITE_OFF', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Cash Register
  getPatientPayments({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'patient-payment/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PATIENTS_PAYMENTS', res.data.results)
          commit('SET_PATIENTS_PAYMENTS_COUNT', res.data.count)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getPatientPayment({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-payment/${id}/`,
        method: 'GET'
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  addPatientPayment({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'patient-payment/',
        method: 'POST',
        data: data,
      })
        .then(res => {
          commit('ADD_PATIENT_PAYMENT', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editPatientPayment({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-payment/${payload.id}/`,
        method: 'PATCH',
        data: payload
      })
        .then(res => {
          commit('EDIT_PATIENT_PAYMENT', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deletePatientPayment({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-payment/${id}/`,
        method: 'DELETE'
      })
        .then(res => {
          commit('DELETE_PATIENT_PAYMENT', id)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getCashRegisterTotal({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'cash-register-total/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_CASH_REGISTER_TOTAL', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addIntegratorDailyRevenue({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator-daily-revenue/`,
        method: 'POST',
        data: payload
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editIntegratorDailyRevenue({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator-daily-revenue/${payload.id}/`,
        method: 'PATCH',
        data: payload
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getTotalByLineOfWork({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'cash-register-total-by-line-of-work/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_TOTAL_BY_LINE_OF_WORK', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getTotalBySnapshot({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'cash-register-total-by-snapshot/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_TOTAL_BY_SNAPSHOT', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getTotalBySelling({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'cash-register-total-by-selling/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_TOTAL_BY_SELLING', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getTotalByDiscount({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'cash-register-total-by-discount/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_TOTAL_BY_DISCOUNT', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getTotalByPrepayment({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'cash-register-total-by-prepayment/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_TOTAL_BY_PREPAYMENT', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Debtors
  getDebtors({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'debtors/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_DEBTORS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Insurance
  getInsurances({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'insurance/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_INSURANCES', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getInsurancesExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `insurance-export/`, method: 'GET',
        params: params,
        responseType: 'blob',
      })
        .then(res => {
          resolve(res)
        }).catch(error => {
          reject({message: error})
        })
    })
  },
  addInsurance({commit}, formData) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'insurance/',
        method: 'POST',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(res => {
          commit('INSURANCE_ADDED', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editInsurance({commit}, formData) {
    return new Promise((resolve, reject) => {
      axios({
        url: `insurance/${formData.get('id')}/`,
        method: 'PATCH',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(res => {
          commit('INSURANCE_EDITED', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deleteInsurance({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `insurance/${id}/`,
        method: 'DELETE'
      })
        .then(res => {
          commit('DELETE_INSURANCE', id)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getTreatmentDiaryDebt({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `finance/treatment-diary-debt/${id}/`,
        method: 'GET',
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Certificate
  getCertificates({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'certificate/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_CERTIFICATES', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getCertificatesExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `certificate-export/`, method: 'GET',
        params: params,
        responseType: 'blob',
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addCertificate({commit}, formData) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'certificate/',
        method: 'POST',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(res => {
          commit('CERTIFICATE_ADDED', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editCertificate({commit}, formData) {
    return new Promise((resolve, reject) => {
      axios({
        url: `certificate/${formData.get('id')}/`,
        method: 'PATCH',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(res => {
          commit('CERTIFICATE_EDITED', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteCertificate({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `certificate/${id}/`,
        method: 'DELETE'
      })
        .then(res => {
          commit('DELETE_CERTIFICATE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Cashless payments
  getCashlessPayments({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'cashless-payment/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_CASHLESS_PAYMENTS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addCashlessPayment({commit}, formData) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'cashless-payment/',
        method: 'POST',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(res => {
          commit('CASHLESS_PAYMENT_ADD', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editCashlessPayment({commit}, formData) {
    return new Promise((resolve, reject) => {
      axios({
        url: `cashless-payment/${formData.get('id')}/`,
        method: 'PATCH',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(res => {
          commit('CASHLESS_PAYMENT_EDIT', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deleteCashlessPayment({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `cashless-payment/${id}/`,
        method: 'DELETE'
      })
        .then(res => {
          commit('DELETE_CASHLESS_PAYMENT', id)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getCashlessPaymentsExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `cashless-payment-export/`, method: 'GET',
        params: params,
        responseType: 'blob',
      })
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Billing statement
  getBillingStatements({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'billing-statement/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_BILLING_STATEMENTS', res.data.results)
          commit('SET_BILLING_STATEMENTS_COUNT', res.data.count)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getBillingStatement({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `billing-statement/${id}`,
        method: 'GET'
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  addBillingStatement({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'billing-statement/',
        method: 'POST',
        data: data
      })
        .then(res => {
          commit('BILLING_STATEMENT_ADD', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editBillingStatement({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `billing-statement/${data.id}/`,
        method: 'PATCH',
        data: data
      })
        .then(res => {
          commit('BILLING_STATEMENT_EDIT', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  deleteBillingStatement({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `billing-statement/${id}/`,
        method: 'DELETE'
      })
        .then(res => {
          commit('DELETE_BILLING_STATEMENT', id)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getBillingStatementSubscriptionPeriods() {
    return new Promise((resolve, reject) => {
      axios({
        url: 'finance/billing-statement/subscription-period/',
        method: 'GET'
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
}
