import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import reports from './routes/reports'
import patients from './routes/patients'
import dictionary from './routes/dictionary'
import application from './routes/application'
import schedule from '@/router/routes/schedule'
import dentalChat from '@/router/routes/dental-chat'
import notifications from '@/router/routes/notifications'
import wage from '@/router/routes/wage'
import lead from '@/router/routes/lead'
import faq from '@/router/routes/faq'
import serviceCalculation from '@/router/routes/serviceCalculation'
import dentalLaboratory from '@/router/routes/dentalLaboratory'
import i18n from '@/libs/i18n'
import { getProgramVersionName } from '@/composables/currentUser'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'patient-cards' } },
    ...apps,
    ...patients,
    ...dictionary,
    ...application,
    ...pages,
    ...reports,
    ...uiElements,
    ...schedule,
    ...dentalChat,
    ...notifications,
    ...wage,
    ...lead,
    ...faq,
    ...serviceCalculation,
    ...dentalLaboratory,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  processTitle(to.meta.title)
  return next()
})

router.beforeResolve(async (to, _, next) => {
  if (
    to.meta
    && to.meta.validateParam
    && to.meta.validator
    && typeof to.meta.validator === 'function'
    && to.meta.validateParam in to.params
  ) {
    return to.meta.validator(to.params[to.meta.validateParam]) ? next() : next({ name: 'error-404' })
  }
  return next()
})

const processTitle = title => {
  let appName = getProgramVersionName()
  document.title = title ? `${i18n.t(title)} | ${appName}` : appName
}

export default router
