export default {
  patients: [],
  pictures: [],
  histories: [],
  cachedPersonalData: {},
  patient: {},
  patientsForDropdown: [],
  patientVisualInspectionList: [],
  visualInspection: {},
  diseases: {},
  totalCount: 0,
  patientCommonDiseasesList: [],
  commonDiseases: {},
  picture: {},
  teeth: [],
  teethMap: [],
  teethStatuses: [],
  patientDentalStatusList: [],
  rowNumbers: [
      [55, 54, 53, 52, 51, 61, 62, 63, 64, 65],
      [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28],
      [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38],
      [85, 84, 83, 82, 81, 71, 72, 73, 74, 75]
  ],
  patientActiveTab: 0,
  patientTreatmentPlan: null,
  patientTreatmentDiary: [],
  patientTreatmentDiariesPerPage: [],
  patientTreatmentDiariesPerPageTotalCount: 0,
  treatmentDiaryToEdit: {},
  mobilePhoneCodes: [
    {value: 375, text: '+375 (Беларусь)'},
    {value: 1, text: '+1 (США)'},
    {value: 370, text: '+370 (Литва)'},
    {value: 371, text: '+371 (Латвия)'},
    {value: 372, text: '+372 (Эстония)'},
    {value: 380, text: '+380 (Украина)'},
    {value: 44, text: '+44 (Великобритания)'},
    {value: 46, text: '+46 (Швеция)'},
    {value: 48, text: '+48 (Польша)'},
    {value: 49, text: '+49 (Германия)'},
    {value: 7, text: '+7 (Казахстан)'},
    {value: 7, text: '+7 (Россия)'},
    {value: 972, text: '+972 (Израиль)'},
  ],
  documentTypes: [
    {value: 'passport', text: 'Паспорт'},
    {value: 'residence-permit', text: 'Вид на жительство'},
    {value: 'foreign-citizen-passport', text: 'Паспорт иностранного гражданина'},
    {value: 'credential', text: 'Удостоверение'},
    {value: 'other', text: 'Иное'},
  ],
  advisoryOpinions: [],
  paymentHistory: [],
  insuranceHistory: [],
  certificateHistory: [],
  dentalWorkHistory: [],
  leadHistoryPerPage: [],
  leadHistoryPerPageTotalCount: 0,
  physicianProtocolHistory: [],
  synevoAnalysesOrders: [],
  patientSynevoAnalysisOrderStatus: [
    {
      value: 'new',
      bgColor: '#00cfe8',
      color: '#FFFFFFFF'
    },
    {
      value: 'sent',
      bgColor: '#7367f0',
      color: '#FFFFFFFF'
    },
    {
      value: 'has-result',
      bgColor: '#28c76f',
      color: '#FFFFFFFF'
    },
    {
      value: 'cancelled',
      bgColor: '#FF0001FF',
      color: '#FFFFFFFF'
    },
  ],
  outpatientSurgeryHistory: [],
  dentalCardInsertForm1K: null,
  dentalCardInsertForm1M: null,
  dentalCardInsertForm1MResults: [],
  dentalCardStates: [],
  callHistoryPerPage: [],
  callHistoryPerPageTotalCount: 0,
  recommendedVisitsPerPage: [],
  recommendedVisitsPerPageCount: 0,
}
