export default {
  // Document settings
  documentSettings: [],
  // Patient reception schedule settings
  patientReceptionScheduleSettings: [],
  // Cash register settings
  cashRegisterSettings: [],
  // Price settings
  priceSettings: [],
  // Treatment diary settings
  treatmentDiarySettings: [],
  // Employee settings
  employeeSettings: [],
  // Dental service settings
  dentalServiceSettings: [],
  // Patient card settings
  patientCardSettings: [],
}
