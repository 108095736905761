import appConstants from '@/constants/constants'
import router from '@/router'
import store from '@/store'
import { mixins } from '@/mixins'
import { getUserData } from '@/auth/utils'
import { playNewMessageSound } from '@/composables/audioHelper'

export const processNewChatMessageByRoute = data => {
  if (
    [
      appConstants.router.chat.APP_USER_CHAT, appConstants.router.chat.APP_PATIENT_CHAT
    ].includes(router.currentRoute.name)
  ) {
    store.commit('chat/SET_NEW_MESSAGE', data)
  } else {
    processNewChatMessage(data)
  }
}

export const processNewChatMessage = data => {
  const message = data.message
  if (message.type === 'Viber') {
    mixins.methods.newMessageViberNotify(
      message.sender_full_name, message.text, 'primary', {timeout: false}
    )
  } else if (message.type === 'Telegram') {
    mixins.methods.newMessageTelegramNotify(
      message.sender_full_name, message.text, 'info', {timeout: false}
    )
  } else {
    mixins.methods.newMessageNotify(message.sender_full_name, message.text, 'info', {timeout: false})
  }
  playNewMessageSound()
}

export const viberApiEnabled = () => {
  const userData = getUserData()
  return userData && userData.viber_api_enabled
}

export const telegramApiEnabled = () => {
  const userData = getUserData()
  return userData && userData.telegram_api_enabled
}

export const enabledChatMessengers = () => {
  const types = []
  if (viberApiEnabled()) {
    types.push(appConstants.chat.messengerType.VIBER)
  }
  if (telegramApiEnabled()) {
    types.push(appConstants.chat.messengerType.TELEGRAM)
  }
  return types
}

export const getTelegramIcon = () => {
  return store.state.appConfig.layout.skin === 'dark'
    ? require(`@/assets/images/contacts/telegram-dark-theme.svg`)
    : require(`@/assets/images/contacts/telegram-white.svg`)
}

export const getViberIcon = () => {
  return store.state.appConfig.layout.skin === 'dark'
    ? require(`@/assets/images/contacts/viber-dark-theme.svg`)
    : require(`@/assets/images/contacts/viber-white.svg`)
}

