import Vue from 'vue'
import store from '@/store'
import { getId } from '@/composables/currentUser'
import ToastificationCustom from '@/views/components/toasts/ToastificationCustom.vue'
import ToastificationNewLead from '@/views/components/toasts/ToastificationNewLead.vue'
import ToastificationPatientProcessedAppointmentNotification
  from '@/views/components/toasts/ToastificationPatientProcessedAppointmentNotification.vue'
import { mixins } from '@/mixins'
import router from '@/router'
import { addDataToLocalStorageByPrefix } from '@/composables/localStorageHelper'
import appConstants from '@/constants/constants'
import { entityLead, entityPatient } from '@/libs/acl/entities'
import ability from '@/libs/acl/ability'
import { routeIsAvailable } from '@/composables/permissionsHelper'
import { saveFileFromResponse } from '@/composables/fileHelper'
import { msgBoxConfirmAction } from '@/composables/bvModal'

export const usersLimitNotify = () => {
  showCenteredNotify(
    'users-limit-notify',
    'Внимание!',
    'Вы превысили максимально доступное количество пользователей для вашего тарифа.'
  )
}

export const subscriptionExpiredNotify = async text => {
  await loadOnlineSystemNotifications()
  showCenteredNotify('subscription-expired-notify', 'Уведомление!', text)
}

export const minimumSmsNotificationBalanceNotify = async text => {
  await loadOnlineSystemNotifications()
  showCenteredNotify('minimum-sms-notification-balance', 'Уведомление!', text)
}

export const systemNotify = notif => {
  showCenteredNotify(
    'system-notify',
    'Уведомление!',
    notif.text,
    notif.id ? notif.id : null,
    notif.files && notif.files.length ? notif.files : []
  )
}

export const newLeadNotify = notif => {
  Vue.$toast({
    component: ToastificationNewLead,
    props: {
      id: notif.message.id,
      date: notif.message.date,
      source: notif.message.source,
      patientFullName: notif.message.patient_full_name,
      phoneCode: notif.message.mobile_phone_code,
      phone: notif.message.mobile_phone,
      time: notif.message.time,
      chatId: notif.message.chat_id
    },
  }, {closeOnClick: true, timeout: false, position: 'bottom-left'})
}

export const processIncomingPatientCallNotif = data => {
  const message = data.message
  if (message.patient_id) {
    processIncomingPatientCallNotifAsPatient(message)
  } else if (message.lead_history_id) {
    processIncomingPatientCallNotifAsLead(message, false)
  } else {
    processIncomingPatientCallNotifAsLead(message, true)
  }
}

const processIncomingPatientCallNotifAsLead = async (message, newLead = false) => {
  const routeName = 'apps-leads'
  if (!ability.can('read', entityLead) || !routeIsAvailable(routeName)) {
    return
  }
  const title = `Входящий звонок с номера ${message.phone_code}${message.phone_number}. Перейти к лиду?`
  await msgBoxConfirmAction(title)
    .then(result => {
      if (result) {
        if (newLead) {
          addDataToLocalStorageByPrefix(
            appConstants.lead.onlineNotif.APP_NEW_LEADS_LOCAL_STORAGE_KEY,
            {isNewLead: true, code: message.phone_code, number: message.phone_number}
          )
        } else {
          addDataToLocalStorageByPrefix(
            appConstants.lead.onlineNotif.APP_NEW_LEADS_LOCAL_STORAGE_KEY,
            {id: message.lead_history_id, date: message.date}
          )
        }
        if (router.currentRoute.name !== 'apps-leads') {
          router.push({name: 'apps-leads'})
        } else {
          window.location.reload()
        }
      }
    })
}

const processIncomingPatientCallNotifAsPatient = async message => {
  const routeName = 'patient-cards'
  if (!ability.can('read', entityPatient) || !routeIsAvailable(routeName)) {
    return
  }
  const title = `Входящий звонок с номера ${message.phone_code}${message.phone_number} (Пациент ` +
    `${message.patient_full_name}). Открыть карточку пациента?`
  await msgBoxConfirmAction(title)
    .then(result => {
      if (result) {
        router.push({
          name: 'app-user-edit',
          params: {
            patientId: message.patient_id,
            activeTabToShow: 0,
            routerBreadcrumbParams: {sourceItemLabel: message.patient_full_name}
          },
          query: {
            sourceItemLabel: message.patient_full_name,
            activeTabToShow: 0
          }
        })
      }
    })
}

export const patientProcessedAppointmentNotification = notif => {
  Vue.$toast({
    component: ToastificationPatientProcessedAppointmentNotification,
    props: {
      appointmentId: notif.message.appointment_id,
      title: notif.message.title,
      patientId: notif.message.patient_id,
      patientFullName: notif.message.patient_full_name,
      phoneCode: notif.message.patient_mobile_phone_code,
      phone: notif.message.patient_mobile_phone,
      note: notif.message.note,
      rate: notif.message.rate,
      appointmentDate: mixins.methods.formatDate(notif.message.appointment_date),
      action: notif.message.action
    },
  }, {closeOnClick: true, timeout: false, position: 'bottom-left'})
}

const showCenteredNotify = (type, title, text, notifId = null, files = []) => {
  Vue.$toast({
    component: ToastificationCustom,
    props: {
      title: title,
      text: text,
      files: files
    },
    listeners: {
      'click-toast': () => processToastClick(type, notifId)
    },
  }, {
    closeOnClick: true, timeout: false, position: 'top-center', toastClassName: 'centered', draggable: false,
    id: `toast-centered-${notifId}`
  })
}

const processToastClick = async (type, notifId) => {
  const toastClassName = 'Vue-Toastification__toast centered'
  const toastID = `toast-centered-${notifId}`
  const elems = document.getElementsByClassName(toastClassName)
  if (elems.length) {
    const elem = elems[0]
    const className = 'margin-correction'
    if (!elem.classList.contains(className)) {
      elem.classList.add(className)
    }
  }
  Vue.$toast.dismiss(toastID)
  if (type === 'subscription-expired-notify') {
    await viewSubscriptionExpiredNotification()
  } else if (type === 'minimum-sms-notification-balance') {
    await viewMinimumSmsNotificationBalanceNotification()
  } else if (type === 'system-notify' && notifId) {
    await store.dispatch('notification/confirmSeenNotification', notifId)
  }
}


export const processUserOperationNotification = async message => {
  if (message.type === 'success') {
    if (message.data) {
      if (message.data.module) {
        switch (message.data.module) {
          case 'service-calculation':
            if (store.getters['serviceCalculation/exportProcessActive']) {
              await store.dispatch(
                  'serviceCalculation/getServiceCalculationExportFile', {filename: message.data.filename}
                )
                .then(({ data }) => {
                  saveFileFromResponse(data, message.data.filename)
                  mixins.methods.successOperationEternalNotify(message.text, message.title, 'InfoIcon')
                })
                .catch(
                  () => mixins.methods.failedOperationEternalNotify('Не удалось сохранить файл выгрузки')
                )
            }
            break
        }
      }
    } else {
      mixins.methods.successOperationEternalNotify(message.text, message.title, 'InfoIcon')
    }
  } else {
    mixins.methods.failedOperationEternalNotify(message.text, message.title)
  }
}

export const loadOnlineSystemNotifications = async () => await store.dispatch(
  'notification/getOnlineSystemNotifications'
)

export const viewSubscriptionExpiredNotification = async () => await store.dispatch(
  'notification/confirmSeenSubscriptionExpiredNotification', getId()
  ).then(async () => await loadOnlineSystemNotifications())

export const viewMinimumSmsNotificationBalanceNotification = async () => await store.dispatch(
  'notification/confirmSeenMinimumSmsNotificationBalanceNotification', getId()
).then(async () => await loadOnlineSystemNotifications())
