import { entityPurchaseOrder } from '@/libs/acl/entities'
import { ceilNumberIsValid } from '@/composables/validator'

export default [
  {
    path: '/apps/dental-laboratory/purchase-order',
    name: 'apps-purchase-order',
    component: () => import('@/views/dental-laboratory/purchase-order/PurchaseOrder.vue'),
    meta: {
      resource: entityPurchaseOrder,
      action: 'read',
      title: 'Purchase order'
    },
  },
  {
    path: '/apps/dental-laboratory/purchase-order/add',
    name: 'apps-purchase-order-add',
    component: () => import(
      '@/views/dental-laboratory/purchase-order/purchase-order-form/PurchaseOrderForm.vue'
    ),
    meta: {
      resource: entityPurchaseOrder,
      action: 'read',
      title: 'Purchase order creation',
      isCreate: true,
    },
  },
  {
    path: '/apps/dental-laboratory/purchase-order/edit/:id',
    name: 'apps-purchase-order-edit',
    component: () => import(
      '@/views/dental-laboratory/purchase-order/purchase-order-form/PurchaseOrderForm.vue'
    ),
    meta: {
      resource: entityPurchaseOrder,
      action: 'read',
      title: 'Edit purchase order',
      isEdit: true,
    },
  },
  {
    path: '/apps/dental-laboratory/purchase-order/view/:id',
    name: 'apps-purchase-order-view',
    component: () => import(
      '@/views/dental-laboratory/purchase-order/purchase-order-form/PurchaseOrderForm.vue'
    ),
    meta: {
      resource: entityPurchaseOrder,
      action: 'read',
      title: 'View purchase order',
      isView: true,
    },
  },
  {
    path: '/apps/dental-laboratory/purchase-order/:orderId/purchase-order-act/add',
    name: 'apps-purchase-order-act-add',
    component: () => import(
      '@/views/dental-laboratory/purchase-order/purchase-order-act/PurchaseOrderActForm.vue'
    ),
    meta: {
      resource: entityPurchaseOrder,
      action: 'read',
      validateParam: 'orderId',
      validator: ceilNumberIsValid,
      title: 'Purchase order act creation',
      isCreate: true,
    },
  },
  {
    path: '/apps/dental-laboratory/purchase-order/:orderId/purchase-order-act/edit',
    name: 'apps-purchase-order-act-edit',
    component: () => import(
      '@/views/dental-laboratory/purchase-order/purchase-order-act/PurchaseOrderActForm.vue'
      ),
    meta: {
      resource: entityPurchaseOrder,
      action: 'read',
      validateParam: 'orderId',
      validator: ceilNumberIsValid,
      title: 'Purchase order act edit',
      isEdit: true,
    },
  },
]
