export default {
    suppliers: [],
    suppliersCount: 0,
    supplierToEdit: null,
    products: [],
    productsCount: 0,
    productToEdit: null,
    bucket: [],
    bucketCount: 0,
    orders: [],
    ordersCount: 0,
    order: null,
    emailSettings: {
        email: null,
        password: null,
    },
    orderStatuses: {
        "WAIT": 'Ожидайте',
        "ACCEPT": 'Письмо отправлено',
        "ABORT": 'Отмена',
        "SEND_ERROR": 'Ошибка отправки',
    },
    bvModal: null
}
