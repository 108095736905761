import { isNotEnoughMaterialByLot } from '@/composables/materialHelper'
import { mixins } from '@/mixins'
import { addDataToLocalStorageByPrefix, getDataFromLocalStorageByPrefix } from '@/composables/localStorageHelper'

export const serviceHasNotEnoughMaterial = (service, count = 1) => {
  let has = false
  count = count ? parseFloat(count) : null
  if (
    count && mixins.methods.isProhibitZeroMaterialConsumption() && service.consumption_rate
    && service.consumption_rate.length
  ) {
    service.consumption_rate.forEach(rate => {
      const lot = rate.material_lots && rate.material_lots.length ? rate.material_lots[0] : null
      if (!has && isNotEnoughMaterialByLot(rate.part_rate * count, lot)) {
        has = true
      }
    })
  }
  return has
}

export const sortServicesByPositions = (services, localStorageKey) => {
  let servicesList = []
  if (services.length) {
    const positions = getDataFromLocalStorageByPrefix(localStorageKey)
    const excludedIds = []
    if (positions) {
      Object.keys(positions).map(k => {
        if (!services.find(s => s.id.toString() === k)) {
          excludedIds.push(k)
        }
      })
    }
    const result = []
    const newServices = []
    services.map(s => {
      if (positions && s.id.toString() in positions) {
        const oldPosition = positions[s.id]
        let counter = 0
        if (excludedIds) {
          counter = excludedIds.reduce((v, i) =>  oldPosition > positions[i] ? v + 1 : v, 0)
        }
        result[oldPosition - counter] = s
      } else {
        newServices.push(s)
      }
    })
    servicesList = result.concat(newServices)

    const data = {}
    servicesList.map((s, i) => data[s.id] = i)
    addDataToLocalStorageByPrefix(localStorageKey, data)
  } else {
    servicesList = []
  }
  return servicesList
}
