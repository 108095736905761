import axios from '@/libs/axios'
import router from '../../router'
import { initialAbility } from '@/libs/acl/config'
import ability from '@/libs/acl/ability'
import store from '@/store'
import { mixins } from '@/mixins'
import { entityPatient, entityPatientCall } from '@/libs/acl/entities'
import { getUserData } from '@/auth/utils'
import navMenuItems from '@/navigation/horizontal'
import moment from 'moment-timezone'
import {
  minimumSmsNotificationBalanceNotify,
  subscriptionExpiredNotify,
  systemNotify,
} from '@/composables/notificationHelper'
import { addDataToLocalStorageByPrefix } from '@/composables/localStorageHelper'
import appConstants from '@/constants/constants'

const processLogin = (commit, rootGetters, response) => {
  commit('info/emptyAbilities', null, { root: true })
  const token = response.data.access
  localStorage.setItem('token', 'Bearer ' + token)
  localStorage.setItem('refresh_token', response.data.refresh)
  if (response.data.isRoot) {
    commit('info/setRootAbilities', null, {root: true})
  } else {
    commit('info/setAbilities', response.data.permissions, {root: true})
    commit('info/appendAbilities', initialAbility, {root: true})
  }
  localStorage.setItem(
    'user',
    JSON.stringify({
      id: response.data.id,
      fullName: response.data.last_name + ' ' + response.data.first_name + ' ' + response.data.middle_name,
      last_name: response.data.last_name,
      first_name: response.data.first_name,
      middle_name: response.data.middle_name,
      phone: response.data.phone,
      birth_date: response.data.birth_date,
      avatar: response.data.avatar ? response.data.avatar.src : null,
      email: 'admin@demo.com',
      role: response.data.role,
      role_type: response.data.role_type,
      username: response.data.username,
      ability: rootGetters['info/abilities'],
      extras: {eCommerceCartItemsCount: 5},
      isRoot: response.data.isRoot,
      integrator_id: response.data.integrator_id,
      integrator_name: response.data.integrator_name,
      program_version: response.data.program_version,
      subscription_expired_at: response.data.subscription_expired_at,
      subscription_month_price: response.data.subscription_month_price,
      service_calculation_export_enabled: response.data.service_calculation_export_enabled,
      patient_contact_view_password_sending_method: response.data.patient_contact_view_password_sending_method,
      system_modules: response.data.system_modules,
      logo_svg: response.data.logo_svg,
      users_limit: response.data.users_limit,
      two_f_a_auth_enabled: response.data.two_f_a_auth_enabled,
      price_settings: response.data.price_settings,
      viber_api_enabled: response.data.viber_api_enabled,
      telegram_api_enabled: response.data.telegram_api_enabled,
      branch_logic_enabled: response.data.branch_logic_enabled,
      ate_enabled: response.data.ate_enabled,
      has_integrator_branches: response.data.has_integrator_branches,
      integrator_branch_id: response.data.integrator_branch_id,
      document_settings: response.data.document_settings,
      treatment_diary_documents: response.data.treatment_diary_documents,
      treatment_diary_settings: response.data.treatment_diary_settings,
      patient_card_settings: response.data.patient_card_settings,
      patient_reception_schedule_settings: response.data.patient_reception_schedule_settings,
      integrator_type: response.data.integrator_type,
      employee_settings: response.data.employee_settings,
      dental_service_settings: response.data.dental_service_settings,
      cash_register_settings: response.data.cash_register_settings,
      timezone: response.data.timezone,
      online: response.data.online,
      sending_sms_to_patient_is_possible: response.data.sending_sms_to_patient_is_possible,
      sms_notification_balance: response.data.sms_notification_balance
    })
  )
  if (response.data.timezone) {
    moment.tz.setDefault(response.data.timezone)
  }
  commit('auth_success', token)
  commit('set_user', response.data)
  commit('schedule/SET_DOCTORS', [], {root: true})
  ability.update(store.getters['info/abilities'])
  if (
    mixins.methods.getUserRoleType() === 'operator' && mixins.methods.isATEEnabled()
      && ability.can('read', entityPatientCall)
  ) {
    addDataToLocalStorageByPrefix(appConstants.user.onlineStatus.IS_ONLINE_LOCAL_STORAGE_KEY, {online: true})
  }
  redirectAfterLogin(response.data)
}

const processLogout = (commit, resolve) => {
  if (
    mixins.methods.getUserRoleType() === 'operator' && mixins.methods.isATEEnabled()
      && ability.can('read', entityPatientCall)
  ) {
    addDataToLocalStorageByPrefix(appConstants.user.onlineStatus.IS_ONLINE_LOCAL_STORAGE_KEY, {online: false})
  }
  commit('logout')
  commit('CLEAR_USER_SOCKET_CONNECTION')
  localStorage.removeItem('user')
  commit('info/emptyAbilities', null, {root: true})
  localStorage.removeItem('token')
  delete axios.defaults.headers.common['Authorization']
  // router.push('/login')
  if (!router.currentRoute.path.endsWith('/login')) {
    router.push('/login')
  }
  resolve()
}

const redirectAfterLogin = data => {
  const systemModules = data.system_modules
  const isRoot = data.isRoot
  const defaultComponentRoute = 'patient-cards'
  if (isRoot) {
    router
      .push({name: defaultComponentRoute})
      .then(() => afterRedirect(data))
  } else {
    const index = systemModules.findIndex(system_module => system_module === defaultComponentRoute)
    if (index > -1) {
      router
        .push({name: defaultComponentRoute})
        .then(() => afterRedirect(data))
    } else {
      router
        .push({name: processNavMenuItems(navMenuItems, systemModules)})
        .then(() => afterRedirect(data))
    }
  }
}

const processNavMenuItems = (items, systemModules) => {
  for (let i = 0; i < items.length; i++) {
    const routeIndex = systemModules.findIndex(module => module === items[i].route)
    if (routeIndex > -1) {
      if (items[i].children) {
        const target = processNavMenuItems(items[i].children, systemModules)
        if (target) {
          return target
        }
      } else {
        const routeIndex = router.options.routes.findIndex(route => route.name === items[i].route)
        if (routeIndex > -1) {
          const route = router.options.routes[routeIndex]
          if (
            route.meta
            && Array.isArray(route.meta.resource)
              ? route.meta.resource.some(r => ability.can(route.meta.action || 'read', r))
              : ability.can(route.meta.action || 'read', route.meta.resource)
          ) {
            return items[i].route
          }
        }
      }
    }
  }
}

const afterRedirect = (data) => {
  mixins.methods.connectToUserSocket()
  if (data.unseen_notifications) {
    processSystemNotifications(data.unseen_notifications)
  }
  if (data.subscription_expires_notif) {
    subscriptionExpiredNotify(data.subscription_expires_notif)
  }
  if (data.minimum_sms_notification_balance_notif) {
    minimumSmsNotificationBalanceNotify(data.minimum_sms_notification_balance_notif)
  }
  if (data.unread_chat_messages) {
    processUnreadChatMessages(data.unread_chat_messages)
  }
}

const processSystemNotifications = notifications => {
  if (notifications && notifications.length) {
    if (notifications.length === 1) {
      systemNotify(notifications[0])
    } else {
      notifications.map(notif => mixins.methods.oldSystemNotify(notif))
    }
  }
}

const processUnreadChatMessages = messages => {
  if (messages.length) {
    messages.map(m =>
      mixins.methods.newMessageNotify(
        m.sender_full_name,
        m.text,
        'info',
        {timeout: false}
      )
    )
  }
}

export default {
  loginByLoginPass({commit, getters, rootState, rootGetters}, params) {
    return new Promise((resolve, reject) => {
      axios.post('auth/token/', params)
        .then(response => {
          const index = response.data.permissions.findIndex(rule => rule === `view_${entityPatient}`)
          const useTwoFAAuth = params.useTwoFAAuth && response.data.two_f_a_auth_enabled
          if (!useTwoFAAuth && index > -1) {
            processLogin(commit, rootGetters, response)
          }
          resolve(response)
        })
        .catch(error => reject({message: error}))
    })
  },
  logout({commit}) {
    const userData = getUserData()
    if (userData) {
      return new Promise(resolve => {
        axios({url: `user/${userData.id}/logout`, method: 'PATCH'})
          .then(() => processLogout(commit, resolve))
          // .catch(() => mixins.methods.failedOperationNotify('Не удалось покинуть систему'))
          .catch(error => processLogout(commit, resolve))
      })
    } else if (!router.currentRoute.path.endsWith('/login')) {
      router.push('/login')
    }
  },
  fetchAccessToken() {
    return new Promise((resolve, reject) => {
      jwt.refreshToken().then(response => {
          resolve(response)
        }
      ).catch(err => {
        reject({message: err})
      })
    })
  },
  registerNotificationToken({commit}, token) {
    return new Promise((resolve, reject) => {
      axios.post('register-notif-token/', {
        registration_id: token,
        type: 'web'
      }).then(response => {
        resolve(response)
      })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // sms-auth
  sendSmsCode({commit}, params) {
    return new Promise((resolve, reject) => {
      axios.post('send-sms-code/', null, {headers: {Authorization: `Bearer ${params.token}`}})
        .then(response => resolve(response))
        .catch(error => reject({message: error}))
    })
  },
  applySmsCode({commit, getters, rootState, rootGetters}, params) {
    return new Promise((resolve, reject) => {
      axios.post('apply-sms-code/', params.data, {headers: {Authorization: `Bearer ${params.token}`}})
        .then(response => {
          processLogin(commit, rootGetters, params.authResponse)
          resolve(response)
      })
      .catch(error => reject({message: error}))
    })
  },

}
